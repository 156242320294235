<template>
  <v-dialog v-model="showUpgradeLicenceUserDialog" persistent max-width="600px">
    <v-card :loading="loadingUpgradeDialog">
      <v-form
        lazy-validation
        v-model="form"
        ref="upgradeLicenceForm"
        :disable="loadingUpgradeDialog"
      >
        <v-card-title>
          <span class="text-h6"
            >Upgrade Licence
            <span class="black--text">{{ currentLicence.label }}</span>
            to:
            <span class="indigo--text" v-if="selectedLicenceKey">{{
              selectedLicenceKey.licence_name
            }}</span></span
          >
          <v-spacer></v-spacer>
          <v-btn @click="closeUpgradeLicenceDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-combobox
            v-model="selectedLicenceKey"
            :rules="[rules.required]"
            :items="licenceKeys"
            label="Select Licence"
            persistent-hint
            return-object
            item-text="licence_name"
            single-line
            outlined
            required
            item-value="id"
          >
          </v-combobox>

          <small v-if="selectedLicenceKey"
            >*This license will be upgraded to the
            <span class="green--text">{{ selectedLicenceKey.licence_name }}</span>
            license with new usages.</small
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="upgradeUserLicence"
            :disabled="!form || loadingUpgradeDialog"
          >
            Upgrade
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default {
  data () {
    return {
      rules: {
        required: (v) => !!v || 'This field is required'
      },
      selectedLicenceKey: null,
      form: true,
      loadingUpgradeDialog: false
    }
  },
  props: {
    showUpgradeLicenceUserDialog: { type: Boolean, required: true },
    licenceKeys: { type: Array, required: true },
    currentLicence: { type: Object, required: true }
  },
  methods: {
    closeUpgradeLicenceDialog () {
      this.$emit('closeUpgradeLicenceUserDialog')
      this.$refs.upgradeLicenceForm.reset()
    },
    async upgradeUserLicence () {
      if (this.$refs.upgradeLicenceForm.validate()) {
        this.form = false
        this.loadingUpgradeDialog = true
        const upgradeLicencePayload = {
          licenceId: this.selectedLicenceKey.id,
          userLicenceId: this.currentLicence.id
        }
        await apiService
          .post('admin-upgrade-user-licence', upgradeLicencePayload)
          .then(() => {
            this.loadingUpgradeDialog = false
            this.closeUpgradeLicenceDialog()
          })
          .catch(() => {
            Swal.fire(
              'Error!',
              'An error occurred while upgrade the license!',
              'error'
            )
          })
          .finally(() => {
            this.form = true
            this.loadingUpgradeDialog = false
          })
      }
    }
  }
}
</script>

<style>
</style>
