<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      left
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="grey darken-1" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-account-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="indigo">mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ userSource.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                userSource.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="isBlocked" color="indigo"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Blocked</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-tooltip v-if="!userSource.deleted" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="red"
                x-small
                text
                @click="deleteUser(userSource.id)"
              >
                <span class="red--text"
                  >DELETE <v-icon small>mdi-delete-outline</v-icon></span
                >
              </v-btn>
            </template>
            <span
              >The user will not be deleted completely. <br />
              Only the deleted state will change.</span
            >
          </v-tooltip>

          <v-btn
            v-else
            color="orange"
            x-small
            text
            @click="restoreUser(userSource.id)"
          >
            <span class="orange--text"
              >RESTORE <v-icon small>mdi-arrow-left-top</v-icon></span
            >
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn x-small text @click="menu = false"> Cancel </v-btn>
          <v-btn
            :disabled="userSource.blocked === isBlocked"
            @click="blockUser(userSource.id)"
            x-small
            color="primary"
            text
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default {
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    isBlocked: false,
    hints: true
  }),
  mounted () {
    this.isBlocked = this.userSource.blocked
  },
  props: {
    userSource: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    async deleteUser (userId) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The user will be deleted.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .put(`admin-delete-user/${userId}`)
            .then(() => {
              this.$emit('call-users')
            })
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          this.syncUserLicences()
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
        }
      })
    },
    async restoreUser (userId) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The user will be active.',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore the user!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .put(`admin-set-active-user/${userId}`)
            .then(() => {
              this.$emit('call-users')
            })
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          this.syncUserLicences()
          Swal.fire('Restored!', 'The user is active.', 'success')
        }
      })
    },
    async blockUser (userId) {
      let text = ''
      let confirmButtuonText = ''
      if (this.isBlocked) {
        text = 'blocked.'
        confirmButtuonText = 'block'
      } else {
        text = 'active.'
        confirmButtuonText = 'active'
      }

      const data = {
        isBlocked: this.isBlocked
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `The user will be ${text}`,
        showCancelButton: true,
        confirmButtonText: `Yes, set ${confirmButtuonText} the user!`
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .put(`admin-blocked-user/${userId}`, data)
            .then(() => {
              this.$emit('call-users')
            })
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          this.syncUserLicences()
          Swal.fire('Blocked!', 'The user is blocked.', 'success')
        }
      })
    }
  }
}
</script>
