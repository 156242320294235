<template>
  <div>
    <v-dialog persistent v-model="showSubscriptionDetailsDialog" width="500">
      <v-card class="mx-auto">
        <v-toolbar dense color="indigo" dark>
          <v-toolbar-title> Subscription Details</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeGeneratedLicenceKeysDialog" icon>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list class="transparent">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >> <span class="text-decoration-underline">Licence Name:</span>
                <span class="font-weight-medium">
                  {{ subscriptionDetails.licence_name }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                >
                <span class="text-decoration-underline">Limit:</span>
                <span class="font-weight-medium">
                  {{ subscriptionDetails.limit }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >>
                <span class="text-decoration-underline">Amount Per Month:</span>
                <span class="font-weight-medium">
                  ${{ subscriptionDetails.amount_per_month }}</span
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >> <span class="text-decoration-underline">Total Amount:</span>
                <span class="font-weight-medium">
                  ${{ subscriptionDetails.amount }}</span
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >>
                <span class="text-decoration-underline"
                  >Subscription Type:</span
                >
                <span class="text-uppercase font-weight-medium">
                  {{ subscriptionDetails.sub_type }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >>
                <span class="text-decoration-underline"
                  >Subscription Status:</span
                >
                <span class="font-weight-medium">
                  {{
                    subscriptionDetails.is_canceled
                      ? ` Licence will cancel at ${timeParser(licenceEndtime)}`
                      : ` Licence will renewal at ${timeParser(licenceEndtime)}`
                  }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import converter from '@/mixins/converter'
export default {
  props: {
    generatedLicenceKeys: { type: Array, default: () => [] },
    showSubscriptionDetailsDialog: { type: Boolean, default: false },
    subscriptionDetails: { type: Object, required: true },
    licenceName: { type: String, required: true, default: '' },
    licenceEndtime: { type: String, required: true, default: '' }
  },
  data () {
    return {
      headersFeature: [
        {
          text: 'Licence Name',
          value: 'licence_name',
          align: 'left'
        },
        { text: 'Renewal type', value: 'sub_type', align: 'center' },
        { text: 'Per amount', value: 'amount_per_month', align: 'center' },
        { text: 'Total Amount', value: 'amount', align: 'center' },
        { text: 'Cancel', value: 'is_canceled', align: 'center' }
        // { text: 'Actions', value: 'actions', align: 'center' }
      ]
    }
  },
  mixins: [converter],
  methods: {
    deleteGeneratedLicenceKey (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "The license will be permanently deleted. You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .get(`admin-delete-permanently-generated-licence/${item.id}`)
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
        }
      })
    },
    closeGeneratedLicenceKeysDialog () {
      this.$emit('closeSubscriptionDetailsDialog')
    }
  }
}
</script>

    <style>
</style>
