<template>
  <v-dialog persistent v-model="devicesInfoForDeleteLicence" width="500">
    <v-card class="mx-auto">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            Notice about devices
          </v-list-item-title>
          <v-list-item-subtitle>{{
            licenceForDevicesInfo.label
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="red">mdi-alert</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="red--text"
          >If this licence is deleted, it will be removed <br />
          from the devices that listed below.</v-list-item-subtitle
        >
      </v-list-item>

      <v-list color="grey lighten-5" class="transparent">
        <v-list-item>
          <span class="text-decoration-underline">Devices List</span>
        </v-list-item>
        <v-list-item
          v-for="item in licenceForDevicesInfo.devices"
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="d-inline-block text-truncate"
                    :style="'max-width:20vw'"
                  >
                    {{ item.device_client_name }}
                  </span>
                </template>
                <span>{{ item.device_client_name }}</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate"
                    :style="'max-width:10vw'"
                  >
                    {{ item.device_label + ' | ' + item.created_at }}
                  </span>
                </template>
                <span>{{ item.device_label + ' | ' + item.created_at }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDevicesInfoForDeleteLicence" color="grey" text>
          CANCEL</v-btn
        >
        <v-btn @click="deleteLicence" color="red" text> KEEP DELETING </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import converter from '../../mixins/converter'
import { apiService } from '../../../services/api.service'
export default {
  data: () => ({
    menu: false,
    labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
    time: 0,
    forecast: [
      {
        day: 'Tuesday',
        icon: 'mdi-white-balance-sunny',
        temp: '24\xB0/12\xB0'
      },
      {
        day: 'Wednesday',
        icon: 'mdi-white-balance-sunny',
        temp: '22\xB0/14\xB0'
      },
      { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' }
    ]
  }),
  mixins: [converter],
  props: {
    devicesInfoForDeleteLicence: {
      type: Boolean,
      default: false
    },
    licenceForDevicesInfo: {
      type: Object,
      required: true
    },
    licenceName: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeDevicesInfoForDeleteLicence () {
      this.$emit('closeDevicesInfoForDeleteLicence')
    },
    deleteLicence () {
      const clientIds = []
      for (let i = 0; i < this.licenceForDevicesInfo.generated_licences.length; i++) {
        clientIds[i] = this.licenceForDevicesInfo.generated_licences[i].assigned_device?.id
      }
      const deleteLicencePayload = {
        user_id: this.licenceForDevicesInfo.user_id,
        licence_id: this.licenceForDevicesInfo.id,
        client_ids: clientIds
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "The license will be permanently deleted. You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .post('admin-delete-permanently-user-licence', deleteLicencePayload)
            .then(() => {
              this.$emit('callSyncUserLicences')
              this.closeDevicesInfoForDeleteLicence()
            })
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
        }
      })
    }
  }
}
</script>

  <style>
</style>
