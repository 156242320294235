<template>
  <div>
    <v-dialog persistent v-model="showGeneratedKeysDialog" width="40%">
      <v-card>
        <v-toolbar dense color="indigo" dark>
          <v-toolbar-title>Licence: {{ licenceName }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn @click="closeGeneratedLicenceKeysDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table :headers="headersFeature" :items="generatedLicenceKeys">
          <template v-slot:[`item.label`]="{ item }">
            <span>{{ item.feature_name }}</span>
          </template>

          <template v-slot:[`item.is_generated`]="{ item }">
            <v-chip
              label
              class="white--text"
              small
              v-if="item.is_generated"
              color="#8e8e8e"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Assigned</span>
                </template>
                <span>This licence is already assigned to a device</span>
              </v-tooltip></v-chip
            >
            <v-chip label v-else small color="blue-grey lighten-3"
              ><span class="white--text">Assignable</span></v-chip
            >
          </template>

          <template v-slot:[`item.assigned_device`]="{ item }">
            <span v-if="item.assigned_device.device_label">
              {{ item.assigned_device.device_label }}
            </span>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                  >mdi-minus</v-icon
                >
              </template>
              <span>License doensn't assigned to a device yet.</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.data_usage`]="{ item }">
            <v-chip
              label
              small
              v-if="item.data_usage.length > 0"
              color="#FEEAE1"
            >
              {{ item.data_usage[0].current_data_usages }}
              /
              {{ item.data_usage[0].total_limits.data_usage_limits }} GB
            </v-chip>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                  >mdi-minus</v-icon
                >
              </template>
              <span>License doensn't assigned to a device yet.</span>
            </v-tooltip>
          </template>

          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="deleteGeneratedLicenceKey(item)" small icon
              ><v-icon color="red">mdi-delete-outline</v-icon> </v-btn
            >
          </template> -->
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default {
  props: {
    generatedLicenceKeys: { type: Array, default: () => [] },
    showGeneratedKeysDialog: { type: Boolean, default: false },
    licenceName: { type: String, default: '' }
  },
  data () {
    return {
      headersFeature: [
        {
          text: 'ID',
          value: 'id',
          align: 'left'
        },
        { text: 'Status', value: 'is_generated', align: 'center' },
        { text: 'Device Name', value: 'assigned_device', align: 'center' },
        { text: 'User Licence Id', value: 'user_licence_id', align: 'center' },
        { text: 'Data Usage', value: 'data_usage', align: 'center' }
        // { text: 'Actions', value: 'actions', align: 'center' }
      ]
    }
  },
  methods: {
    deleteGeneratedLicenceKey (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "The license will be permanently deleted. You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .get(`admin-delete-permanently-generated-licence/${item.id}`)
            .catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
        }
      })
    },
    closeGeneratedLicenceKeysDialog () {
      this.$emit('closeGeneratedKeysDialog')
    }
  }
}
</script>

  <style>
</style>
