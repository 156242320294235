<template>
    <div>
      <v-card>
        <v-card-title color="rgba(0, 0, 0, 0)" flat>
          <div>
            <v-divider class="ml-2" vertical></v-divider>
          </div>
          <span class="font-weight-medium mr-2">Device List </span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchUserLicence"
            class="mr-2"
            clearable
            dense
            hide-details
            label="Search device.."
            outlined
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-card-title>

        <v-data-table :headers="headersFeature" :items="userDevices">
          <template v-slot:[`item.label`]="{ item }">
            <span>{{ item.feature_name }}</span>
          </template>

          <template v-slot:[`item.is_generated`]="{ item }">
            <v-chip
              label
              class="white--text"
              small
              v-if="item.licence_key_id"
              color="#8e8e8e"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Assigned</span>
                </template>
                <span>This licence is already assigned to a device</span>
              </v-tooltip></v-chip
            >
            <v-chip label v-else small color="blue-grey lighten-3"
              ><span class="white--text">Assignable</span></v-chip
            >
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ timeParserDate(item.created_at, 'detailTime') }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </template>

<script>
import converter from '@/mixins/converter'
export default {
  props: {
    userDevices: { type: Array, default: () => [] },
    licenceName: { type: String, default: '' }
  },
  data () {
    return {
      searchUserLicence: '',
      headersFeature: [
        {
          text: 'Assigned Licence ID',
          value: 'licence_key_id',
          align: 'center'
        },
        { text: 'Device Name', value: 'device_client_name', align: 'center' },
        { text: 'Device Label', value: 'device_label', align: 'center' },
        { text: 'Created At', value: 'created_at', align: 'center' }
      ]
    }
  },
  mixins: [converter],
  methods: {
    closeGeneratedLicenceKeysDialog () {
      this.$emit('closeGeneratedKeysDialog')
    }
  }
}
</script>

      <style>
  </style>
