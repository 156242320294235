
import { apiService } from '../../../services/api.service'
export default {
  props: {
    dialog: { type: Object, required: true },
    user: { type: Object, required: true }
  },
  methods: {
    save (user: any) {
      apiService
        .put(`users/edit/?id=${user.id}`, user)
        .then(() => {
          this.$emit('saved')
          this.dialog.condition = false
        })
        .catch((e) => {
          console.log(e.response)
        })
    },

    close () {
      this.dialog.condition = false
    }
  }
}
